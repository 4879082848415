import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Hero.module.scss';
import Button from '../Button';
import { ucFirst } from '../../utils/caseconverters';
import Image from '../Image';

const Hero = ({ title, preamble, image, primaryLink, secondaryLink, type, small, whiteBackground }) => {
    const classes = classNames(
        styles['Hero'],
        styles['Hero--' + ucFirst(type)], {
            [styles['Hero--Small']]: small,
            [styles['Hero--NoImage']]: !image || !image.file,
            [styles['Hero--White']]: whiteBackground && (!image || !image.file),
        }
    );

    return (
        <div className={classes}>
            {image && (
                <Image
                    {...image}
                    priority={true}
                    style={{ zIndex: -1 }}
                />
            )}

            <div className={styles['Hero__Container']}>
                <div className={styles['Hero__Row']}>
                    <div className={styles['Hero__Content']}>
                        <h1 className={styles['Hero__Title']}>{title}</h1>
                        {preamble && (
                            <p className={styles['Hero__Description']}>
                                {preamble}
                            </p>
                        )}
                    </div>

                    <div className={styles['Hero__Links']}>
                        {primaryLink && primaryLink.href && (
                            <div className={styles['Hero__Link']}>
                                <Button link={primaryLink} />
                            </div>
                        )}

                        {secondaryLink && secondaryLink.href && (
                            <div className={styles['Hero__Link']}>
                                <Button
                                    link={secondaryLink}
                                    modifiers={['secondary']}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

Hero.propTypes = {
    title: PropTypes.string,
    image: PropTypes.object,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.shape({
                href: PropTypes.string,
            }),
            text: PropTypes.string,
        })
    ),
    type: PropTypes.oneOf(['start', 'products']),
};

Hero.defaultProps = {
    type: 'start',
};

export default Hero;
