import React from 'react';
import PropTypes from 'prop-types';
import { basePageWrap } from '../BasePage';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Hero from '../../components/Hero';
import StreamField from '../../components/StreamField';
import s from './HomePage.module.scss';

const HomePage = ({ settings, hero, content, }) => {
    return (
        <div className={s.Container}>
            <Header {...settings.header} />
            <main>
                {hero && <Hero {...hero} />}
                {content && (<StreamField items={content} />)}
            </main>
            <Footer {...settings.footer} />
        </div>
    );
};

HomePage.propTypes = {
    header: PropTypes.object,
    hero: PropTypes.object,
    footer: PropTypes.object,
    content: PropTypes.array,
};

HomePage.defaultProps = {
    settings: {
        header: {},
        hero: {},
        footer: {}
    },
    hero: {},
    content: [],
};

export default basePageWrap(HomePage);
